import React from "react";
import Resume from "../../resume.json";

function AboutMe() {
  return (
    <section className="section my-background" id="about">
      <div className="summary-text columns">
        <div className="column is-4">
          <figure className="image container is-180x180">
            <img
              width="180px"
              height="180px"
              src={Resume.basics.picture}
              alt={Resume.basics.name}
              className="is-rounded"
              onError={(e) => { e.target.onerror = null; e.target.src = Resume.basics.x_pictureFallback }} />
          </figure>
        </div>
        <div className="column is-1"></div>
        <div className="column is-10">
          <h1 className="title has-text-gray-darker">{Resume.basics.name}</h1>
          <h2 className="subtitle has-text-gray-darker">{Resume.basics.title}
          </h2>
          <div className="block is-grouped is-grouped-multiline">
            <a href="files/CV2024DCM.pdf">
              <p className="block button is-dark is-medium is-outlined">Résumé</p>
            </a>
            {Resume.basics.profiles.map((value, index) => {
              return (
                <a
                  href={value.url}
                  target="blank"
                  title={value.network}
                  style={{ color: "black" }}
                >
                  <p className="icon is-dark is-large">
                    <i className={value.x_icon}></i>
                  </p>
                </a>
              );
            })}
          </div>
        </div>
      </div>
      <p className="subtitle summary-text has-text-gray-light has-text-weight-bold">
        {Resume.basics.x_title}
      </p>
      <p className="subtitle summary-text has-text-gray-light has-text-weight-light">
        {Resume.basics.summary}
      </p>
    </section>
  );
}

export default AboutMe;
