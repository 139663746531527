import React from "react";

function BlogCard(props) {
  return (
    <span>{props.title}
      <a
        href={props.url}
        target="blank"
        aria-label="Dev Community"
      >
        Read the full article on Medium
      </a>
    </span>
  );
}

export default BlogCard;
