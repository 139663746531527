import React from "react";

function Article(props) {
  return (
    <div class="container">
      <div class="columns">
        <div class="column">
          <div class="card is-horizontal">
            <div class="columns">
              <div class="column is-3">
                <div class="card-image">
                  <figure class="image is-square">
                    <img src={props.image} alt="" />
                  </figure>
                </div>
              </div>
              <div class="column is-9">
                <div class="card-stacked">
                  <div class="card-content">
                    <div class="media-content">
                      <a href={props.url} target="blank">
                        <p className="card-header-title subtitle">{props.title}</p>
                      </a>
                    </div>

                    <div class="content">
                      <p>{props.extract}</p>
                    </div>
                  </div>
                  <footer class="card-footer">
                    <div class="card-footer-item has-text-left">
                      <a href={props.url} target="blank">
                        See the full project
                      </a>
                    </div>
                  </footer>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr />
    </div>
  );
}

export default Article;
