import React, { Component } from "react";
import BlogCard from "../elements/BlogCard";

class Blog extends Component {
  constructor(props) {
    super();
    this.state = { articles: [] };
  }

  componentDidMount() {
    const devTo = "https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@danielachvzm";

    fetch(devTo)
      .then(response => {
        return response.json();
      })
      .then(data => {
        let articles = [];
        data.items.forEach((element, index) => {
          articles.push(
            <li key={index}>
              <BlogCard
                key={index}
                title={element.title + " "}
                url={element.guid}
              />
            </li>
          );
        });
        this.setState({ articles: articles });
      });
  }

  render() {
    return (
      <section className="section" id="blog">
        <div className="container">
          <h2 className="title is-12">My Latest Blog Posts</h2>
          <div class="content">
            <ul>{this.state.articles}</ul>
          </div>
        </div>
      </section>
    );
  }
}

export default Blog;
